:host {
  --color-gray-40: hsl(0, 0%, 40%);
  --color-gray-60: hsl(0, 0%, 60%);
  --color-gray-85: hsl(0, 0%, 85%);
  --color-gray-98: hsl(0, 0%, 98%);

  --color-digital-blue: hsl(193, 83%, 52%);

  --color-highlight: var(--color-digital-blue);

  --space-xxs:    2px;
  --space-xs:     4px;
  --space-s:      8px;
  --space-m:      16px;
  --space-l:      32px;
  --space-xl:     64px;
  --space-xxl:    128px;

  --space-inset-xxs:   2px 2px 2px 2px;
  --space-inset-xs:    4px 4px 4px 4px;
  --space-inset-s:     8px 8px 8px 8px;
  --space-inset-m:     16px 16px 16px 16px;
  --space-inset-l:     32px 32px 32px 32px;
  --space-inset-xl:    64px 64px 64px 64px;
  --space-inset-xxl:   128px 128px 128px 128px;

  --space-inset-squish-s:     4px 8px 4px 8px;
  --space-inset-squish-m:     8px 16px 8px 16px;
  --space-inset-squish-l:     16px 32px 16px 32px;

  --space-inset-stretch-s:     12px 8px 12px 8px;
  --space-inset-stretch-m:     24px 16px 24px 16px;

  --space-inline-xxs:   0px 2px 0px 0px;
  --space-inline-xs:    0px 4px 0px 0px;
  --space-inline-s:     0px 8px 0px 0px;
  --space-inline-m:     0px 16px 0px 0px;
  --space-inline-l:     0px 32px 0px 0px;
  --space-inline-xl:    0px 64px 0px 0px;
  --space-inline-xxl:   0px 128px 0px 0px;

  --space-inline-reverse-xxs:   0px 0px 0px 2px;
  --space-inline-reverse-xs:    0px 0px 0px 4px;
  --space-inline-reverse-s:     0px 0px 0px 8px;
  --space-inline-reverse-m:     0px 0px 0px 16px;
  --space-inline-reverse-l:     0px 0px 0px 32px;
  --space-inline-reverse-xl:    0px 0px 0px 64px;
  --space-inline-reverse-xxl:   0px 0px 0px 128px;

  --space-stack-xxs:   0px 0px 2px 0px;
  --space-stack-xs:    0px 0px 4px 0px;
  --space-stack-s:     0px 0px 8px 0px;
  --space-stack-m:     0px 0px 16px 0px;
  --space-stack-l:     0px 0px 32px 0px;
  --space-stack-xl:    0px 0px 64px 0px;
  --space-stack-xxl:   0px 0px 128px 0px;

  display: block;
}

* {
  box-sizing: border-box;
}

html, body {
  background: var(--color-gray-98);
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 16px;
}

/* -----------------
Mobile Styles
----------------- */

.gallery-container {
  height: auto;
  width: 99%;
  margin: 0 auto;
}

.header-search {
  padding: var(--space-inset-m);
  background: hsl(0, 0%, 100%);
}

.header-search input {
  width: 100%;
  padding: var(--space-inset-stretch-s);
  background: hsl(0, 0%, 95%);
  border: 1px solid hsl(0, 0%, 95%);
  border-radius: 3px;
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, .18);

  color: var(--color-gray-40);
  font-size: 1rem;

}

.header-search input:focus  {
  border: 1px solid var(--color-highlight);
  outline: none;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, .20);
}



@media ( min-width: 700px ) {

  .header-search {
    padding: var(--space-inset-stretch-m);
    display: flex;
    justify-content: center;
  }

  .header-search div {
    display: inline-block;
    margin: 0 auto;
  }

  .header-search input {
    width: 350px;

  }

  .header-search input:focus {
    border: 1px solid var(--color-highlight);
    outline: none;
    box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, .20);
  }

}

.oskw-hidden {
  opacity: 0;
  transform: scale(.9);
}

.oskw-gallery {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.oskw-gallery * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.oskw-gallery .oskw-card-group {
  width: 100%;
  height: 100%;
  padding: var(--space-inset-s);
  position: relative;
}

.oskw-gallery .oskw-card-group  .oskw-column {
  display: inline-block;
}

.oskw-gallery .oskw-img-wrapper {
  width: 100%;
  height: auto;
  overflow: hidden;
}

.oskw-gallery .oskw-img-wrapper img {
  width: 100%;
  height: auto;
}

.oskw-gallery .oskw-info-message {
  width: 100%;
  color: var(--color-gray-40);
  text-align: center;
  padding: var(--space-inset-stretch-m);
}

@supports (display: flex) and (flex: 1) {
  .oskw-gallery .oskw-card-group {
    display: flex;
  }

  .oskw-gallery .oskw-card-group .oskw-column {
    flex: 1;
  }
}
