.ossy-surface {
  display: flex;
}

.ossy-surface--vertical {
  flex-direction: column;
}

.ossy-surface--horizontal {
  flex-direction: row;
}

.ossy-surface__item {
}

.ossy-surface__item--fill {
  flex: 1 1 auto;
}

.ossy-surface--vertical.ossy-surface--bordered > .ossy-surface__item {
  border-top: 1px solid var(--separator-primary);
}

.ossy-surface--vertical.ossy-surface--bordered > .ossy-surface__item:first-child {
  border-top: none;
}

.ossy-surface--horizontal.ossy-surface--bordered > .ossy-surface__item {
  border-left: 1px solid var(--separator-primary);
}

.ossy-surface--horizontal.ossy-surface--bordered > .ossy-surface__item:first-child {
  border-left: none;
}
