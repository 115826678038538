.dropzone--dragover {
  outline: 1px solid hsl(199, 98%, 43%);
  position: relative;
}

.dropzone--dragover::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: hsla(199, 98%, 43%, .2);
}
