.ossy-stack {
  display: flex;
}

.ossy-stack--vertical {
  flex-direction: column;
}

.ossy-stack--horizontal {
  flex-direction: row;
}

.ossy-stack__item {
}

.ossy-stack__item--fill {
  flex: 1 1 auto;
}

.ossy-stack--vertical.ossy-stack--bordered > .ossy-stack__item {
  border-top: 1px solid var(--separator-primary);
}

.ossy-stack--vertical.ossy-stack--bordered > .ossy-stack__item:first-child {
  border-top: none;
}

.ossy-stack--horizontal.ossy-stack--bordered > .ossy-stack__item {
  border-left: 1px solid var(--separator-primary);
}

.ossy-stack--horizontal.ossy-stack--bordered > .ossy-stack__item:first-child {
  border-left: none;
}
